import { observer } from "mobx-react-lite";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Checkbox, Form, Modal } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import { toast } from "react-toastify";

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    user: {
        id: string,
        name: string,
        roleIds: string[],
        hasClientAccess: boolean
    };
    setUser: React.Dispatch<React.SetStateAction<{
        id: string,
        name: string,
        roleIds: string[],
        hasClientAccess: boolean
    }>>;
    defaultRoles: string[]
}

const RoleForm = ({ open, setOpen, user, setUser, defaultRoles }: Props) => {
    const { userStore, roleStore } = useStore();
    const { isLoading, setRole, filterUsers } = userStore;
    const { getAllRoles } = roleStore;
    const [options, setOptions] = useState([{ key: '', text: '', value: '' }]);

    useEffect(() => {
        getAllRoles().then(roles => {
            const option = { key: '', text: '', value: '' };
            let tempOptions = [option];
            tempOptions = [];

            roles?.map(role => {
                if (!(role.id == "dc7a5d12-ea2a-4851-95b3-3c56c62284be" || role.id == "5ae1f0c5-a7a1-4d2b-85b0-0096ef931bf2")) {
                    tempOptions.push({ key: role.id, text: role.name, value: role.id });
                    setOptions(tempOptions);
                }

            });
        });

    }, []);

    const handleInputChange = (event: any, data: any) => {
        const { value } = data;
        let removedId = user.roleIds.find(x => !value.includes(x));

        if (defaultRoles.find(r => r === removedId)) {
            toast.warning("Default Role should not be removed.")
        }
        else {
            setUser({
                ...user,
                roleIds: value,
            });
        }
    }

    const handleCheckboxChange = () => {
        setUser({
            ...user,
            hasClientAccess: !user.hasClientAccess,
        });
    }

    const handleSubmit = () => {
        setRole({
            userId: user.id,
            roleIds: user.roleIds,
            hasClientAccess: user.hasClientAccess
        }).then(() => {
            filterUsers();
            setOpen(false);
        });
    }

    return (
        <>
            <Modal
                onClose={() => setOpen(false)}
                open={open}
            >
                <Modal.Header>Edit User's Role</Modal.Header>
                <Modal.Content>
                    <Form autoComplete='off'>
                        <label>Name</label>
                        <Form.Input value={user.name} readOnly />
                        <label>Roles</label>
                        <Form.Dropdown
                            name='roleIds'
                            placeholder='Select a Role'
                            fluid
                            multiple
                            selection
                            search
                            value={user.roleIds}
                            options={options}
                            onChange={handleInputChange}
                        />
                        <label>Client Access</label>
                        <Form.Field>
                            <Checkbox
                                toggle
                                checked={user.hasClientAccess}
                                onClick={handleCheckboxChange}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Save"
                        onClick={handleSubmit}
                        primary
                        loading={isLoading}
                    />
                    <Button onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    )
}

export default observer(RoleForm);
