import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import styled from "styled-components";
import StyledHeader from "../../app/common/styled-element/StyledHeader";
import PaginatedTable from "../../app/common/table/PaginatedTable";
import { User } from "../../app/models/user";
import { useStore } from "../../app/stores/store";
import UserRoleForm from '../../features/roles/form/UserRoleForm';

const StyledContainer = styled.div`
        padding: 1em 2em;
`;

const UserList = () => {
    const { userStore } = useStore();
    const { users, total, setPageNumber, pageNumber, isLoading, setSearchQuery, filterUsers, getUsersRole, filterValues } = userStore;

    const [modalState, setmodalState] = useState(false);
    const [user, setUser] = useState({
        id: '',
        name: '',
        roleIds: [''],
        hasClientAccess: false
    });

    const [defaultRoles, setDefaultRoles] = useState([""])

    const getUser = async (userId: string) => {
        await getUsersRole(userId).then(user => {
            if (user) {
                let defaultRole = user.roles
                    .filter(r => r.isDefault === true)
                    .map(m => m.id);

                setDefaultRoles(defaultRole);

                setUser({
                    id: user.id,
                    name: user.name,
                    roleIds: user.roles.map(m => m.id),
                    hasClientAccess: user.isClientUser
                });
            }
        });
    }

    useEffect(() => {
        filterValues.pageNumber = 1;
        filterValues.searchKeyword = '';
        filterUsers();
    }, [filterUsers]);

    return (
        <>
            <StyledContainer>
                <StyledHeader content="User's Role" />
                <PaginatedTable<User>
                    columns={[
                        { title: 'Role', field: 'id', hidden: true },
                        { title: 'Employee', field: 'name' },
                        { title: 'Role', field: 'roles' },

                    ]}
                    actions={[
                        rowData => ({
                            icon: 'edit',
                            tooltip: 'Edit Role',
                            onClick: async (event) => {
                                await getUser(rowData.id);
                                setmodalState(true);
                            }
                        }),
                    ]}
                    total={total}
                    pageSize={10}
                    pageNumber={pageNumber}
                    totalCount={total ?? 0}
                    sorting={false}
                    draggable={false}
                    onChangePage={p => {
                        setPageNumber(p);
                    }}
                    onSearchChange={query => {
                        setSearchQuery(query);

                    }}
                    data={users}
                    isLoading={isLoading}
                    toolbar={true}
                    search={true}
                />
            </StyledContainer>
            <UserRoleForm open={modalState} setOpen={setmodalState} user={user} setUser={setUser} defaultRoles={defaultRoles} />
        </>
    )
}

export default observer(UserList);
