import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { history } from "../..";
import agent from '../api/agents';
import CookieHelper from '../helpers/cookieHelper';
import { Filters } from '../models/filter';
import { ResetPasswordFormValues, User, UserFormValues, UserRole, UserRoleFormValues } from "../models/user";
import { store } from "./store";

const _cookieHelper = new CookieHelper();

export default class UserStore {
    user: User | null = null;
    displayName: string = '';
    users: User[] = [];
    total: number = 0;
    loadingInitial = false;
    isLoading = false;
    filterValues: Filters = { pageNumber: 1, pageSize: 10, searchKeyword: "" };
    editMode = false;
    usersRole: UserRole | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    get isLoggedIn() {
        return !!this.user;
    }

    get pageNumber() {
        return this.filterValues.pageNumber;
    }

    filterUsers = async (initialLoad: boolean = false) => {
        if (initialLoad) this.setLoadingInitial(true);
        this.setLoading(true);
        try {
            const { total, users } = await agent.Users.filter({
                pageNumber: this.filterValues.pageNumber,
                pageSize: this.filterValues.pageSize,
                searchKeyword: this.filterValues.searchKeyword
            });

            runInAction(() => {
                this.users = users;
                this.total = total;
            });

            this.setLoadingInitial(false);
            this.setLoading(false);
        } catch (error) {
            console.error(error);
            this.setLoadingInitial(false);
            this.setLoading(false);
        }
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    setLoading = (state: boolean) => {
        this.isLoading = state;
    }

    setPageNumber = (pageNumber: number) => {
        this.filterValues = { ...this.filterValues, pageNumber: pageNumber };
        this.filterUsers();
    }

    setSearchQuery = (query: string) => {
        this.filterValues = { ...this.filterValues, pageNumber: 1, searchKeyword: query };
        this.filterUsers();
    }

    setDisplayName = (user: User) => {
        if (this.user != null)
            this.displayName = `${user?.firstName} ${user?.lastName}`
    }

    forgotPassword = async (creds: { username: string }) => {
        try {
            const user = await agent.Account.forgotPassword(creds);
            toast.success("Check your email for instructions on how to reset your password.");

            history.push('/login');
        } catch (e) {
            console.error(e);
        }
    }

    resetPassword = async (credentials: ResetPasswordFormValues) => {
        try {
            await agent.Account.resetPassword(credentials);
            toast.success("You have successfully reset your password.");
            history.push('/login');
        } catch (e) {
            console.error(e);
        }
    }

    getUsersRole = async (userId: string) => {
        this.setLoading(true)
        try {
            const user = await agent.Users.GetRoles(userId);
            this.usersRole = user;
            this.setLoading(false);
            return user;
        } catch (e) {
            console.log(e);
        }
    }

    setRole = async (userRoleForm: UserRoleFormValues) => {
        this.isLoading = true;

        try {
            await agent.Users.AssignRoles(userRoleForm);
            this.isLoading = false;
            toast.success("User's Role is successfully updated.");
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.isLoading = false;
            })
            throw error;
        }
    };

    getUser = async () => {
        try {
            const user = await agent.Account.current();
            runInAction(() => {
                this.user = user
                this.setDisplayName(user);
            });
        } catch (e) {
            console.log(e);
        }
    }

    getProfilePicture = async (id: string) => {
        try {
            return await agent.Account.getProfilePhoto(id, {
                responseType: 'blob'
            });
        } catch (e) {
            console.log(e);
        }
    }
}